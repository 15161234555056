<template>
  <v-container>
    <row-header :msg="msgRowHeader" action="non"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <testing-form-component
              :testing_model="testing_model"
              :payment_type="trx_type"
              :product_code="product_code"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="saveData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>

        <v-card class="mt-3">
          <v-card-text>
            <pre>
              {{ testing_model }}
            </pre>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import OverlayImg from "../../../components/content/OverlayImg";
import TestingFormComponent from "../../../components/page/testing/TestingFormComponent";
import sha256 from 'sha256';

const {GetRequest, PostRequest} = require("../../../store/services/users");

export default {
  components: {
    RowHeader,
    OverlayImg,
    TestingFormComponent
  },
  data: () => ({
    msgRowHeader: {newUser: '', newMsg: 'Testing Dev Kilat', actionName: 'non'},
    overlay: false,
    absolute: false,
    valid: true,
    testing_model: {
      timeStamp: '',
      user_id: '',
      ref_id: '',
      bill_id: '',
      appName: '',
      product_code: '',
      merchantToken: '',
    },
    trx_type: [],
    product_code: [],
  }),
  async mounted() {
    this.trx_type = await this.getCatData();
    this.product_code = await this.getProdData();
    const random = await this.generateRandomStr();
    this.testing_model.appName = this.$store.getters.currentUser.bank_code;
    this.testing_model.user_id = this.$store.getters.currentUser._id;
    this.testing_model.ref_id = 'ref_' + this.$moment().format('YYYYMMDDHHmmss') + random;
  },
  methods: {
    async getCatData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('categories');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async getProdData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('products');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async saveData() {
      this.testing_model.timeStamp = this.$moment().format('YYYYMMDDHHmmss');
      this.testing_model.merchantToken = sha256(
          this.testing_model.timeStamp +
          this.testing_model.appName +
          this.testing_model.ref_id +
          this.testing_model.bill_id +
          this.testing_model.product_code +
          this.$secretCode +
          this.testing_model.user_id
      );

      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const res = await PostRequest('inq', this.testing_model);
        this.overlay = this.$store.getters.isLoading;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        console.log(res)
        if (res.result_cd === '00') {
          alert('post data success');
        } else {
          alert(res.result_msg);
        }
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('post data failed ' + e.message);
      }
    },
    async generateRandomStr() {
      return (Math.random() + 1).toString(36).substring(7)
    },
  }
}
</script>

<style scoped>

</style>
