<template>
  <v-card-text>
    <v-row>
      <v-col
          sm="6"
          cols="12"
      >
        <v-autocomplete
            dense
            v-model="category"
            label="Category"
            :items="payment_type"
            item-value="code"
            item-text="name"
            clearable
            :rules="[v => !!v || 'Payment type must filled']"
            @change="onChangeType"
        ></v-autocomplete>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-autocomplete
            dense
            v-model="testing_model.product_code"
            label="Product Code"
            :items="products"
            item-value="code"
            item-text="name"
            clearable
            :rules="[v => !!v || 'Product code must filled']"
        ></v-autocomplete>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="testing_model.bill_id"
            sm="12"
            cols="6"
            label="Bill Id"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "TestingFormComponent",
  data: () => ({
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
    products: [],
    category: '',
  }),
  props: {
    testing_model: Object,
    payment_type: [],
    product_code: [],
  },
  mounted() {
    // console.log(this.product_code)
  },
  methods: {
    onChangeType(cat) {
      if (cat) {
        // this.products = this.product_code;
        // if (val === 'GAME001') {
        //   this.products = this.product_code_game;
        // } else if (val === 'PAKETDATA001') {
        //   this.products = this.product_code_data;
        // }
        this.products = this.product_code.filter(val => {
          return val.category.code === cat
        });
      } else {
        this.products = [];
      }
    }
  }
}
</script>

<style scoped>

</style>
